export default defineNuxtRouteMiddleware(async () => {
  const { currentUser } = useCurrentUser()

  if (!currentUser.value || !currentUser.value.canEditOrganization) {
    // 組織設定ができない場合はindexから遷移しなおさせる
    return await navigateTo({
      name: 'index',
    })
  }
})
